import { Board, Clip, Library } from '@air/api/types';
import { stringify } from 'qs';

import { sanitizeStringForUrl } from '../utils/Url';

export const createBoardRoute = (id: string, title?: string): string =>
  `/b/${title ? sanitizeStringForUrl(title) + '-' : ''}${id}`;

export const createLibraryRoute = (id: string): string => `/l/${id}`;

export const createPersonRoute = (id: string): string => `/p/${id}`;

export const createBoardClipRoute = ({
  clipId,
  boardTitle,
  boardId,
}: {
  clipId: Clip['id'];
  boardTitle: string;
  boardId: Board['id'];
}) => `${createBoardRoute(boardId, boardTitle)}/c/${clipId}`;

export const createSavedFilterRoute = (id: string) => `/saved-filter/${id}`;

export const createLibraryAssetRoute = (libraryId: Library['id'], clipId: Clip['id']) => `/l/${libraryId}/c/${clipId}`;

export const createShortUrlBoardRoute = (shortId: string, subBoardId: string) => `/a/${shortId}/b/${subBoardId}`;

export const createPersonAssetRoute = (personId: string, clipId: Clip['id']) => `/p/${personId}/c/${clipId}`;

export const createShortUrlClipInBoardRoute = (shortId: string, subBoardId: string, clipId: Clip['id']) =>
  `/a/${shortId}/b/${subBoardId}/c/${clipId}`;

export const createShortUrlAssetRoute = (shortId: string, clipId: Clip['id']) => `/a/${shortId}/c/${clipId}`;

export const isBoardRoute = (pathname: string) => pathname.startsWith('/b/');
export const isLibraryRoute = (pathname: string) => pathname.startsWith('/l/');
export const isSavedFilterRoute = (pathname: string) => pathname.startsWith('/saved-filter/');

export const Routes = {
  root: '/',
  apiDocs: 'https://documenter.getpostman.com/view/14122528/2s8YekQaDo#intro',
  apiKeyHelp: 'https://help.air.inc/en/articles/7047153-api-key-access#h_87ab5c7911',
  brandfolderMigration: 'https://www.notion.so/airinc/Brandfolder-Air-bbda48cadae545d0977e7012758e095a',
  enterprise: {
    expansion: 'https://air-inc.na.chilipiper.com/book/expansion',
  },
  home: '/home',
  helpCenter: 'https://help.air.inc',
  createWorkspace: '/workspace/create',
  joinApprovedWorkspace: '/workspace/join',
  joinWorkspace: '/workspace/join/[shortId]',
  joinWorkspaceWithToken: '/workspace/join/[shortId]',
  redirectWorkspace: '/workspace/redirect',
  workspaceMembersLimit: '/workspace/members-limit',
  noWorkspacePermission: '/no-workspace-permission',
  verify: '/verify/[dummyCode]/[token]',
  notFound: '/404',
  import: '/import/',
  imports: {
    googleDrive: '/import/drive',
    homeBox: '/import/box',
    brandfolder: '/import/brandfolder',
    dropbox: '/import/dropbox',
  },
  generalLibrary: createLibraryRoute('general'),
  desktop: {
    openDesktopFile: '/open-desktop-file',
  },
  marketing: {
    root: 'https://air.inc',
    adobePremiereProDownload: 'https://partners.adobe.com/exchangeprogram/creativecloud/exchange.details.107604.html',
    desktop: 'https://air.inc/air-flow',
    pricing: 'https://air.inc/pricing',
    privacy: 'https://air.inc/legal/privacy',
    terms: 'https://air.inc/legal/terms',
  },
  auth: {
    login: '/login',
    logout: '/logout',
    forgot: '/forgot',
    samlSSOLogin: '/saml-sso-login',
    /** Requires "email" query string */
    resetPassword: '/reset',
    oauth: '/oauth',
    signUp: '/sign-up',
  },
  onboarding: {
    initial: '/onboarding/workspace-name',
    approvedDomains: '/onboarding/join-approved-workspaces',
  },
  account: {
    apiAccess: '/account/api',
    myAccount: '/account/my-account',
    changePassword: '/account/change-password',
    notificationSettings: '/account/notifications',
  },
  media: {
    favoriteItems: '/favorite-items',
    all: '/all',
    myUploads: '/my-uploads',
    recentlyAdded: '/recently-added',
    recentlyDeleted: '/recently-deleted',
    recentlyModified: '/recently-modified',
    unusedItems: '/unused-items',
    untaggedItems: '/untagged-items',
  },
  workspaceAdmin: {
    addOns: '/workspace/add-ons',
    checkout: '/workspace/subscription/checkout',
    customFields: '/workspace/custom-fields',
    tags: '/workspace/tags',
    edit: '/workspace/edit',
    invoices: '/workspace/invoices',
    subscription: '/workspace/subscription',
    security: '/workspace/security',
    people: '/workspace/people',
  },
  workspaceSettings: {
    root: '/workspace',
    members: '/workspace/members',
  },
  people: '/people',
  selectSSOProviderToLoginTo: '/select-sso-provider-to-login-to',
};

export const workspaceRoutes = [Object.values(Routes.workspaceSettings), Object.values(Routes.workspaceAdmin)].flat();
export const accountSettingsRoutes = Object.values(Routes.account);

export const createAllContentAssetRoute = (id: string) => `${Routes.media.all}/c/${id}`;

export const getUpgradeByMailPath = (workspaceId: string) => `/workspace/subscription/plan/e/${workspaceId}`;

export const getJoinWorkspaceURL = (shortId: string, email: string) =>
  Routes.joinWorkspace.replace('[shortId]', shortId).concat(`?${stringify({ email })}`);

export const getDesktopAppLink = (pathname: string, search = '') => {
  return `airapp://.${pathname}/${search}`;
  //               ^           ^
  // Both the dot and trailing slash in this url are significant. The
  // desktop app will receive this url and extract pathname using
  // `new URL('airapp://./...')` and the dot ensures that the url is valid
  // since it the pathname will include a backslash at the beginning.
};

export const AirFlowPageLink = 'https://air.inc/air-flow';
